// src/popups/PopupExport.jsx

import React, { useEffect, useRef, useState } from 'react';
import styles from './css/PopupExport.module.css';
import { FaSpinner } from 'react-icons/fa';

// Access the environment variable
const API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE;

const PopupExport = ({ isOpen, onClose, exportData, clientName }) => {
  const popupRef = useRef(null);

  // State for last updated date
  const [lastUpdated, setLastUpdated] = useState(null);

  // State for loading indicator
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // State for success/error messages
  const [message, setMessage] = useState(null);

  // Handle clicking outside the popup or pressing Escape to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEsc);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  // Function to fetch feed update date
  const fetchFeedUpdateDate = async () => {
    if (!clientName) {
      setMessage({ type: 'error', text: 'No client selected.' });
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL_IMAGE}/get-feed-update-date?client_name=${encodeURIComponent(clientName)}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        if (data.feed_update_date) {
          setLastUpdated(new Date(data.feed_update_date).toLocaleString());
        } else {
          setLastUpdated(null);
        }
      } else {
        throw new Error(data.error || 'Failed to fetch feed update date.');
      }
    } catch (error) {
      console.error('Error fetching feed update date:', error);
      setMessage({ type: 'error', text: error.message || 'Failed to fetch feed update date.' });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch feed update date when popup opens
  useEffect(() => {
    if (isOpen) {
      fetchFeedUpdateDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // Function to handle updating the feed
  const handleUpdateFeed = async () => {
    if (!clientName) {
      setMessage({ type: 'error', text: 'No client selected.' });
      return;
    }

    setIsUpdating(true);
    setMessage(null);
    try {
      const response = await fetch(`${API_URL_IMAGE}/update-feed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ client_name: clientName }),
      });

      const data = await response.json();

      if (response.ok) {
        await fetchFeedUpdateDate(); // Refresh the last updated date
        setMessage({ type: 'success', text: data.message || 'Feed updated successfully!' });
      } else {
        throw new Error(data.error || 'Failed to update feed.');
      }
    } catch (error) {
      console.error('Error updating feed:', error);
      setMessage({ type: 'error', text: error.message || 'Failed to update feed. Please try again.' });
    } finally {
      setIsUpdating(false);
    }
  };

  if (!isOpen) return null;

  // Define the supplementary images with labels and URL templates
  const supplementaryImages = [
    {
      id: 1,
      label: (
        <>
          Original + QFE
          <br />
          (with numbers)
        </>
      ),
      src: "https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/originalandqfeandnumbers.png",
      urlTemplate: "https://storage.googleapis.com/quantum-feed-engine/[clientName]/feeds/[clientName]_feed_original_plus_qfe_with_numbers.xml",
    },
    {
      id: 2,
      label: (
        <>
          Original + QFE
        </>
      ),
      src: "https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/originalandqfe.png",
      urlTemplate: "https://storage.googleapis.com/quantum-feed-engine/[clientName]/feeds/[clientName]_feed_original_plus_qfe_without_numbers.xml",
    },
    {
      id: 3,
      label: (
        <>
          Only QFE
          <br />
          (with numbers)
        </>
      ),
      src: "https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/qfeandnumbers.png",
      urlTemplate: "https://storage.googleapis.com/quantum-feed-engine/[clientName]/feeds/[clientName]_feed_only_qfe_with_numbers.xml",
    },
    {
      id: 4,
      label: (
        <>
          Only QFE
        </>
      ),
      src: "https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/qfe.png",
      urlTemplate: "https://storage.googleapis.com/quantum-feed-engine/[clientName]/feeds/[clientName]_feed_only_qfe_without_numbers.xml"
    },
  ];

  // Function to handle "Download XML" button clicks
  const handleImageClick = (urlTemplate) => {
    if (!clientName) {
      alert('Client name is not defined.');
      return;
    }
    const url = urlTemplate.replace(/\[clientName\]/g, encodeURIComponent(clientName));
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // Function to handle "View Feed" button clicks
  const handleViewFeedClick = (urlTemplate) => {
    if (!clientName) {
      alert('Client name is not defined.');
      return;
    }
    const feedUrl = urlTemplate.replace(/\[clientName\]/g, encodeURIComponent(clientName));
    const viewerUrl = `https://feedviewer.s360digital.com/?url=${encodeURIComponent(feedUrl)}`;
    window.open(viewerUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.popupContainer} ref={popupRef}>
        <div className={styles.header}>
          <h2>Export Management</h2>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>

        {/* Part 1: Update Feed */}
        <div className={styles.section}>
          <div className={styles.updateFeedContainer}>
            <button
              className={styles.updateButton}
              onClick={handleUpdateFeed}
              disabled={isUpdating || isLoading}
            >
              {isUpdating ? <FaSpinner className={styles.spinner} /> : 'Update Feeds'}
            </button>
            <div className={styles.lastUpdated}>
              <strong>Last Updated:</strong>{' '}
              {isLoading ? <FaSpinner className={styles.spinner} /> : (lastUpdated || 'Never')}
            </div>
          </div>
          {message && (
            <div
              className={
                message.type === 'success'
                  ? styles.successMessage
                  : styles.errorMessage
              }
            >
              {message.text}
            </div>
          )}
        </div>

        {/* Part 2: Preview Current Supplementary Feed */}
        <div className={styles.section}>
          <h3>Preview Current Supplementary Feed</h3>
          <div className={styles.imagePreviewContainer}>
            {supplementaryImages.map((image) => (
              <div key={image.id} className={styles.imageItem}>
                <img
                  src={image.src}
                  alt="Supplementary Feed Preview"
                  className={styles.previewImage}
                />
                <span className={styles.imageLabel}>{image.label}</span>
                <div className={styles.buttonContainer}>
                  <button
                    className={styles.actionButton}
                    onClick={() => handleImageClick(image.urlTemplate)}
                    disabled={!lastUpdated}
                  >
                    Download
                  </button>
                  <button
                    className={styles.actionButton}
                    onClick={() => handleViewFeedClick(image.urlTemplate)}
                    disabled={!lastUpdated}
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupExport;
