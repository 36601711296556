import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './css/QpeText.css';
import 'react-resizable/css/styles.css';
import { ClipLoader } from 'react-spinners';


const QpeText = ({ token, isAdmin }) => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [clientName, setClientName] = useState('');
  const [xmlLoading, setXmlLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [unselectedColumns, setUnselectedColumns] = useState([]);
  const [pendingChanges, setPendingChanges] = useState({});
  const [selectedColumns, setSelectedColumns] = useState(JSON.parse(localStorage.getItem('selectedColumns')) || []);
  const [selectedColumnItems, setSelectedColumnItems] = useState([]);
  const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [selectedQfeColumns, setSelectedQfeColumns] = useState([]);
  const [popupRowData, setPopupRowData] = useState(null); // State for popup row data
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [activeProcesses, setActiveProcesses] = useState(0);
  const [showFieldSelectionPopup, setShowFieldSelectionPopup] = useState(false); // Add this state
  const [fieldSelection, setFieldSelection] = useState([]); // Add this state for field selection
  const [selectedRowIds, setSelectedRowIds] = useState([]); // Add this state to track selected row IDs
  const qfeColumns = originalData.length > 0 ? Object.keys(originalData[0]).filter(key => key.startsWith('qfe_') && !key.endsWith('_state')) : [];
  const [processedCount, setProcessedCount] = useState(0);
  const hasFetchedInitialData = useRef(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [totalToProcess, setTotalToProcess] = useState(0);
  const [popupFieldSelection, setPopupFieldSelection] = useState([]); // Add this state
  const [isProcessing, setIsProcessing] = useState(false);
  const [generatingRows, setGeneratingRows] = useState([]);
  const [disableEditing, setDisableEditing] = useState(false);
  const [selectedStateColumns, setSelectedStateColumns] = useState([]);
  const [isCaseSensitive, setIsCaseSensitive] = useState(true);
  const [progress, setProgress] = useState(0);
  const [savingCells, setSavingCells] = useState([]);
  const [systemPrompt, setSystemPrompt] = useState('');
  const [imagePrompt, setImagePrompt] = useState('');
  const [fewShots, setFewShots] = useState([{ input: '', output: '' }]);
  const [bulkActionLoading, setBulkActionLoading] = useState(false);
  const [promptConfigMessage, setPromptConfigMessage] = useState('');
  const [promptConfigMessageType, setPromptConfigMessageType] = useState('');
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const resizeColumnRef = useRef(null);
  const [feedUrl, setFeedUrl] = useState('');
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [generationCost, setGenerationCost] = useState(0);
  const [cost, setCost] = useState(0);
  const [publishing, setPublishing] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});
  const [popupMessage, setPopupMessage] = useState('');
  const [popupMessageType, setPopupMessageType] = useState('');
  const [defaultSystemPrompt, setDefaultSystemPrompt] = useState('');
  const [defaultImagePrompt, setDefaultImagePrompt] = useState('');
  const [defaultFewShots, setDefaultFewShots] = useState([]);
  const [popupSaving, setPopupSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [loadingToggles, setLoadingToggles] = useState([]); // Add this state
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0); // Add this state
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [promptConfigurations, setPromptConfigurations] = useState([]);
  const [selectedPromptConfiguration, setSelectedPromptConfiguration] = useState('');
  const [filters, setFilters] = useState([{ key: '', operator: 'contains', value: '', logic: 'AND' }]);
  const [filterSectionVisible, setFilterSectionVisible] = useState(false);
  const [showStateUpdatePopup, setShowStateUpdatePopup] = useState(false);
  const [stateFieldSelection, setStateFieldSelection] = useState([]);
  const [stateUpdateType, setStateUpdateType] = useState('active');  // To track whether activating or deactivating
  const [stateUpdateChoice, setStateUpdateChoice] = useState('active');
  const [pastedIds, setPastedIds] = useState('');
  const [showPastedIdsBox, setShowPastedIdsBox] = useState(false);
  const [showFeedPopup, setShowFeedPopup] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [orderBy, setOrderBy] = useState(""); // Default order by 'id'
  const [orderDirection, setOrderDirection] = useState('asc');  // Default order direction 'asc'
  const [replacePopupVisible, setReplacePopupVisible] = useState(false);
  const [replaceColumn, setReplaceColumn] = useState('');
  const [replaceOriginalValue, setReplaceOriginalValue] = useState('');
  const [replaceNewValue, setReplaceNewValue] = useState('');
  const [isProcessingReplace, setIsProcessingReplace] = useState(false);
  const [updatedRowCount, setUpdatedRowCount] = useState(0);

  


  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientName = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/config`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClientName(response.data.client_name);
      } catch (err) {
        console.error('Failed to fetch client name', err);
      }
    };

    fetchClientName();
  }, [token]);

  useEffect(() => {
    console.log('Selected Columns Updated:', selectedColumns);
    localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  useEffect(() => {
    const savedColumns = JSON.parse(localStorage.getItem('selectedColumns')) || [];
    setSelectedColumns(savedColumns);
  }, []);
  
  


  const handleClientChange = (event) => {
    const selectedClient = event.target.value;
    setGlobalLoading(true); // Set loading state when client changes
    setSelectedClient(selectedClient);
    hasFetchedInitialData.current = false; // Reset fetch state
    setSelectedRows({}); // Deselect all rows
    localStorage.setItem('selectedClient', selectedClient); // Save to localStorage
  };
  
  
  
  
  useEffect(() => {
    const savedClient = localStorage.getItem('selectedClient');
    if (savedClient) {
      setSelectedClient(savedClient);
    }
  }, []);
  
  
  
  
  

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/config`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClients(response.data.linked_clients);
      } catch (err) {
        console.error('Failed to fetch clients', err);
      }
    };
  
    fetchClients();
  }, [token]);


  useEffect(() => {
    const fetchInitialDataIfNeeded = async () => {
      if (!hasFetchedInitialData.current && selectedClient) {
        setGlobalLoading(true); // Set loading state before fetching data
        await fetchInitialData(selectedClient, page, perPage);
        hasFetchedInitialData.current = true;
        setGlobalLoading(false); // Reset loading state after fetching data
      }
    };
  
    fetchInitialDataIfNeeded();
  }, [selectedClient, page, perPage]);
  
  
  useEffect(() => {
    const fetchPromptConfigurations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/client-data`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const clientData = response.data.find(client => client.client_name === selectedClient);
        setPromptConfigurations(clientData.prompt_combinations || []);
        setCurrentBalance(clientData.credit_balance || 0);  // Add this line to set the current balance
      } catch (err) {
        console.error('Failed to fetch prompt configurations', err);
      }
    };
  
    if (selectedClient) {
      fetchPromptConfigurations();
    }
  }, [selectedClient, token]);
  


  const handlePromptConfigurationChange = (event) => {
    setSelectedPromptConfiguration(event.target.value);
  };
  

  useEffect(() => {
    const fetchInitialDataIfNeeded = async () => {
      if (!hasFetchedInitialData.current && selectedClient) {
        await fetchInitialData(selectedClient, page, perPage);
        hasFetchedInitialData.current = true;
      }
    };
    fetchInitialDataIfNeeded();
  }, [selectedClient, page, perPage]);
  

  const fetchInitialData = async (client, page, perPage, filters, ids = [], orderBy = 'id', orderDirection = 'asc') => {
    if (!client) return;
  
    setGlobalLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/load-data`, {
        client_name: client,
        page,
        per_page: perPage,
        filters,
        ids,
        order_by: orderBy,
        order_direction: orderDirection
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const combinedData = JSON.parse(response.data.data);
      setData(combinedData);
      setOriginalData(combinedData);
      setTotalItems(response.data.total_items);
  
      // Calculate total pages
      const calculatedTotalPages = Math.ceil(response.data.total_items / perPage);
      setTotalPages(calculatedTotalPages);
  
      if (combinedData.length > 0) {
        const allColumns = Object.keys(combinedData[0]);
        const filteredUnselectedColumns = allColumns.filter(key => 
          !selectedColumns.includes(key) && !key.includes('_state')
        );
        setUnselectedColumns(filteredUnselectedColumns);
      }
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    } finally {
      setGlobalLoading(false);
    }
  };
  
  
  useEffect(() => {
    if (originalData.length > 0) {
      const allColumns = Object.keys(originalData[0]);
      const filteredUnselectedColumns = allColumns.filter(key => 
        !selectedColumns.includes(key) && !key.includes('_state')
      );
      setUnselectedColumns(filteredUnselectedColumns);
    }
  }, [selectedColumns, originalData]);
  
  
  

  const fetchLatestData = async (client) => {
    if (!client) return;
  
    setGlobalLoading(true);
    setXmlLoading(true); // Set loading state for the button
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/xml-data`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { client_name: client }
      });
      console.log('Fetch Latest Data response:', response.data);
  
      // Call fetchInitialData to update the frontend with the latest data
      await fetchInitialData(client, page, perPage, filters);
    } catch (error) {
      console.error('There was an error fetching the latest data!', error);
    } finally {
      setGlobalLoading(false);
      setXmlLoading(false); // Reset loading state for the button
    }
  };


  const handleReplaceAction = () => {
    setReplacePopupVisible(true);
  };
  
  const handleReplaceConfirm = async () => {
    if (!replaceColumn || !replaceOriginalValue || !replaceNewValue) {
        return;
    }

    setIsProcessingReplace(true); // Start processing

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/replace-value`, {
            client_name: selectedClient,
            column: replaceColumn,
            original_value: replaceOriginalValue,
            new_value: replaceNewValue,
            case_sensitive: isCaseSensitive,
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 200) {
            setUpdatedRowCount(response.data.updated_count); // Set the updated row count
            // Refresh the data
            await fetchInitialData(selectedClient, page, perPage, filters, pastedIds, orderBy, orderDirection);
        } else {
            console.error('Error updating values:', response.data.message);
        }
    } catch (error) {
        console.error('There was an error processing the replacement:', error);
    } finally {
        setIsProcessingReplace(false); // End processing
    }
};

  
  
  
  


  
  

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) {
      return;
    }
    setPage(newPage);
    setSelectedRows({}); // Deselect all rows
    fetchInitialData(selectedClient, newPage, perPage, filters);
  };
  

  const handlePerPageChange = (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    setPerPage(newPerPage);
    setPage(1);
    setSelectedRows({}); // Deselect all rows
    fetchInitialData(selectedClient, 1, newPerPage, filters);
  };
  
  const handleFilterChange = (index, key, value) => {
    const newFilters = [...filters];
    newFilters[index][key] = value;
    setFilters(newFilters);
  };

  const handleAddFilter = () => {
    setFilters([...filters, { key: '', operator: 'contains', value: '', logic: 'AND' }]);
  };

  const handleRemoveFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const handleApplyFilter = () => {
    setSelectedRows({});
    setPage(1);
    const separators = /[\s,]+/; // Regex to match spaces, commas, or newlines
    const ids = pastedIds.split(separators).map(id => id.trim()).filter(id => id !== '');
    fetchInitialData(selectedClient, 1, perPage, filters, ids);
  };

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
    fetchInitialData(selectedClient, 1, perPage, filters, pastedIds, event.target.value, orderDirection);
  };
  
  const handleOrderDirectionChange = (event) => {
    setOrderDirection(event.target.value);
    fetchInitialData(selectedClient, 1, perPage, filters, pastedIds, orderBy, event.target.value);
  };
  

  const combinedColumns = [...new Set([...selectedColumns, ...unselectedColumns])];

  const sortedColumns = combinedColumns.sort((a, b) => {
    if (a.startsWith('qfe_') && !b.startsWith('qfe_')) {
      return 1;
    }
    if (!a.startsWith('qfe_') && b.startsWith('qfe_')) {
      return -1;
    }
    return a.localeCompare(b);
  });

  const handleDownloadAllData = async () => {
    if (selectedClient) {
      try {
        setIsDownloading(true);
        setGlobalLoading(true);
  
        // Process pasted IDs
        const separators = /[\s,]+/; // Regex to match spaces, commas, or newlines
        const ids = pastedIds.split(separators).map(id => id.trim()).filter(id => id !== '');
  
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/download-data`, {
          client_name: selectedClient,
          selected_columns: selectedColumns,
          filters,
          ids,
          order_by: orderBy,
          order_direction: orderDirection,
        }, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        });
  
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${selectedClient}_data.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the data:', error);
      } finally {
        setGlobalLoading(false);
        setIsDownloading(false);
      }
    }
  };
  
  
  
  
  
  
  



  const handleResizeStart = (index, event) => {
    event.stopPropagation();
    document.body.classList.add('resizing');
    resizeColumnRef.current = { index, startX: event.clientX, startWidth: columnWidths[index] || 200 };
    document.addEventListener('mousemove', handleResizing);
    document.addEventListener('mouseup', handleResizeEnd);
  };

  const handleResizing = (event) => {
    event.preventDefault();
    const { index, startX, startWidth } = resizeColumnRef.current;
    const newWidth = Math.max(startWidth + (event.clientX - startX), 50);
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [index]: newWidth,
    }));
  };

  const handleResizeEnd = () => {
    document.body.classList.remove('resizing');
    document.removeEventListener('mousemove', handleResizing);
    document.removeEventListener('mouseup', handleResizeEnd);
    resizeColumnRef.current = null;
  };

  const handleOpenFeedPopup = () => {
    setShowFeedPopup(true);
  };

  const handleCloseFeedPopup = () => {
    setShowFeedPopup(false);
  };


  const handleEdit = async (id, key, value) => {
    if (generatingRows.includes(id)) {
      return; // Prevent editing if row is being generated
    }
  
    const row = data.find(row => row.id === id);
    const originalValue = originalData.find(row => row.id === id)[key];
    if (originalValue === value || (!originalValue && !value)) {
      return; // Do nothing if value hasn't changed
    }
  
    // Update the frontend optimistically
    setData(prevData => prevData.map(row => row.id === id ? { ...row, [key]: value } : row));
  
    setSavingCells(prev => [...prev, { id, key }]);
    setSaving(true);
  
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/qpe-data/${id}`, 
        { [key]: value, client_name: selectedClient }, 
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // Update the originalData to reflect the latest changes
      setOriginalData(prevData => prevData.map(row => row.id === id ? { ...row, [key]: value } : row));
    } catch (error) {
      console.error('There was an error updating the data!', error);
  
      // Roll back the change in case of an error
      setData(prevData => prevData.map(row => row.id === id ? { ...row, [key]: originalValue } : row));
    } finally {
      setSavingCells(prev => prev.filter(cell => cell.id !== id || cell.key !== key));
      if (savingCells.length === 1) setSaving(false); // Only set saving to false if there are no more cells being saved
    }
  };
  

  
// Handle confirm field selection
const handleConfirmFieldSelection = () => {
  const selectedIds = Object.keys(selectedRows).filter((id) => selectedRows[id]);
  const cost = selectedIds.length; // Assuming 1 credit per item

  setGenerationCost(cost);
  setShowConfirmationPopup(true); // Show confirmation popup
};
  
  
const GlobalLoadingIndicator = ({ loading }) => (
  loading ? (
    <div className="global-loading-overlay">
      <div className="global-loading-spinner">
        <ClipLoader size={50} color={"#848EE0"} loading={loading} />
      </div>
    </div>
  ) : null
);

  const handleChange = (id, key, value) => {
    setPendingChanges(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [key]: value,
      },
    }));
  
    setData(prevData => prevData.map(row =>
      row.id === id ? { ...row, [key]: value } : row
    ));
  };
  
  const handleBlur = async (id, key, value, fromPopup = false) => {
    if (fromPopup) return; // Skip blur event for the popup
  
    const originalValue = originalData.find(row => row.id === id)[key];
    if (originalValue !== value && !(originalValue === undefined && value === '')) {
      setSavingCells(prev => [...prev, { id, key }]); // Add the cell to saving state
      await handleEdit(id, key, value);
      setSavingCells(prev => prev.filter(cell => !(cell.id === id && cell.key === key))); // Remove the cell from saving state
    }
  };
  
  
  
  

  const getBackgroundColor = (state, selected, generating) => {
    if (generating) {
      return selected ? '#FFDDC1' : '#FFD4A1'; // Example colors for generating state
    }
    let color;
    switch (state) {
      case 'draft':
        color = selected ? '#E6D8A8' : '#FFF2CC';
        break;
      case 'active':
        color = selected ? '#B6D7A8' : '#D9EAD3';
        break;
      default:
        color = selected ? '#A9A9A9' : 'transparent';
    }
    return color;
};


  const handleRowSelection = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = {
        ...prevSelectedRows,
        [id]: !prevSelectedRows[id],
      };
      const selectedIds = Object.keys(newSelectedRows).filter((id) => newSelectedRows[id]);
      const cost = selectedIds.length; // Assuming 1 credit per item

      setGenerationCost(cost);
      return newSelectedRows;
    });
  };


  const handleItemClick = (column, listType, event) => {
    if (event.shiftKey) {
      const items = listType === 'unselected' ? unselectedColumns : selectedColumns;
      const selectedColumnItemsCopy = [...selectedColumnItems];
      const lastSelectedIndex = items.findIndex(col => col === selectedColumnItemsCopy[selectedColumnItemsCopy.length - 1]);
      const currentIndex = items.findIndex(col => col === column);
      const [start, end] = [lastSelectedIndex, currentIndex].sort((a, b) => a - b);
      const newSelectedItems = items.slice(start, end + 1);
  
      newSelectedItems.forEach(item => {
        if (!selectedColumnItemsCopy.includes(item)) {
          selectedColumnItemsCopy.push(item);
        }
      });
  
      setSelectedColumnItems(selectedColumnItemsCopy);
    } else {
      setSelectedColumnItems(prevItems =>
        prevItems.includes(column) ? prevItems.filter(item => item !== column) : [...prevItems, column]
      );
    }
  };

  const moveToSelected = () => {
    const selectedCols = unselectedColumns.filter(col => selectedColumnItems.includes(col));
    setSelectedColumns([...selectedColumns, ...selectedCols]);
    setUnselectedColumns(unselectedColumns.filter(col => !selectedColumnItems.includes(col)));
    setSelectedColumnItems([]);
  };

  const moveToUnselected = () => {
    const selectedCols = selectedColumns.filter(col => selectedColumnItems.includes(col));
    setUnselectedColumns([...unselectedColumns, ...selectedCols]);
    setSelectedColumns(selectedColumns.filter(col => !selectedColumnItems.includes(col)));
    setSelectedColumnItems([]);
  };

  const onDragStart = (index) => {
    setDraggedColumnIndex(index);
  };
  
  const onDragOver = (index) => {
    if (draggedColumnIndex === null || draggedColumnIndex === index) {
      return;
    }
  
    const columns = [...selectedColumns];
    const draggedColumn = columns[draggedColumnIndex];
    columns.splice(draggedColumnIndex, 1);
    columns.splice(index, 0, draggedColumn);
  
    setDraggedColumnIndex(index);
    setSelectedColumns(columns);
  };
  
  const onDragEnd = () => {
    setDraggedColumnIndex(null);
  };


  const handlePopupChange = (id, key, value) => {
    setPopupRowData(prevData => ({
      ...prevData,
      [key]: value,
    }));
    setHasUnsavedChanges(true); // Mark as having unsaved changes
  };


  const handlePopupClose = () => {
    if (hasUnsavedChanges) {
      setShowWarning(true); // Show warning if there are unsaved changes
    } else {
      closePopup(); // Close the popup directly
    }
  };


  const handleCloseFieldSelectionPopup = () => {
    setShowFieldSelectionPopup(false);
  };

  const handleCloseConfirmationPopup = () => {
    setShowConfirmationPopup(false);
  };

  const handleCloseStateUpdatePopup = () => {
    setShowStateUpdatePopup(false);
  };
 
  
  function handlePopupOverlayClick(e) {
    if (
      e.target.className === 'feed-popup-overlay' ||
      e.target.className === 'popup-overlay new-popup-overlay' ||
      e.target.className === 'popup-overlay'
    ) {
      if (hasUnsavedChanges) {
        setShowWarning(true);
      } else {
        handleCloseFeedPopup();
        handlePopupClose();
        handleCloseFieldSelectionPopup();
        handleCloseConfirmationPopup();
        handleCloseStateUpdatePopup();
      }
    }
  }
  


  const confirmClosePopup = () => {
    setPopupRowData(null); // Close the popup
    setHasUnsavedChanges(false); // Reset unsaved changes state
    setSaveSuccess(false); // Reset save success state
    setShowWarning(false); // Hide warning
  };

  const closePopup = () => {
    setPopupRowData(null); // Close the popup
    setHasUnsavedChanges(false); // Reset unsaved changes state
    setSaveSuccess(false); // Reset save success state
  };
  
  
  
  const handleToggleState = async (id, key) => {
    const stateKey = `${key}_state`;
    const currentState = popupRowData[stateKey];
    const newState = currentState === 'active' ? 'draft' : 'active';
  
    setActiveProcesses(prev => prev + 1); // Increment active process count
    setLoadingStates(prev => ({ ...prev, [`${id}_${stateKey}`]: true }));
  
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/qpe-data/${id}`,
        { [stateKey]: newState, client_name: selectedClient },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      setPopupRowData(prevData => ({
        ...prevData,
        [stateKey]: newState,
      }));
  
      setData(prevData => prevData.map(row =>
        row.id === id ? { ...row, [stateKey]: newState } : row
      ));
    } catch (error) {
      console.error('There was an error updating the state!', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, [`${id}_${stateKey}`]: false }));
      setActiveProcesses(prev => prev - 1); // Decrement active process count
    }
  };


  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Logout successful');
    } catch (err) {
      console.error('Error logging out', err);
    }
    localStorage.removeItem('token');
    navigate('/login');
  };
  
 
  
  const handlePopupFieldSelectionChange = (field) => {
    setPopupFieldSelection(prevSelection => {
      if (prevSelection.includes(field)) {
        return prevSelection.filter(item => item !== field);
      } else {
        return [...prevSelection, field];
      }
    });
  };


  

  const handleGenerateItemInPopup = async () => {
    setActiveProcesses(prev => prev + 1); // Increment active process count
    setGenerating(true);
    setSaving(true);

    try {
        const requestData = {
            selected_ids: [popupRowData.id],
            selected_fields: popupFieldSelection, // Use selected fields from the popup
            client_name: selectedClient, // Send the already selected client
            prompt_configuration: selectedPromptConfiguration // Add selected prompt configuration
        };

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-qfe-fields`, requestData, {
            headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.message === 'Not enough credits') {
            setPromptConfigMessageType('error');
            setPromptConfigMessage('Not enough credits to generate items. Please purchase more tokens.');
        } else {
            const result = response.data.results[0];
            const newCreditBalance = response.data.new_credit_balance;

            // Update the credit balance in the frontend state
            setCurrentBalance(newCreditBalance);

            if (result.status === 'success') {
                const updatedData = data.map(row =>
                    row.id === result.id ? { ...row, ...result.data } : row
                );
                setData(updatedData);
                setOriginalData(updatedData);
                setPopupRowData(updatedData.find(row => row.id === popupRowData.id));
            } else {
                console.error(`Failed to generate QFE fields for ${result.id}`, result.message);
                setPromptConfigMessageType('error');
                setPromptConfigMessage(`Failed to generate QFE fields for ${result.id}`);
            }
        }
    } catch (err) {
        console.error('Failed to generate QFE fields', err);
        if (err.response && err.response.data && err.response.data.message) {
            setPromptConfigMessageType('error');
            setPromptConfigMessage(err.response.data.message);
        } else {
            setPromptConfigMessageType('error');
            setPromptConfigMessage('Failed to generate QFE fields');
        }
    } finally {
        setSaving(false);
        setGenerating(false);
        setActiveProcesses(prev => prev - 1); // Decrement active process count
    }
};
  
  
  
  
  const handleStateFieldSelectionChange = (field) => {
    setStateFieldSelection(prevSelection => {
        if (prevSelection.includes(field)) {
            return prevSelection.filter(item => item !== field);
        } else {
            return [...prevSelection, field];
        }
    });
};


const handleStateUpdatePopup = () => {
  setShowStateUpdatePopup(true);
};

const handleStateUpdateChoiceChange = (choice) => {
  setStateUpdateChoice(choice);
};


const handleStateUpdate = async () => {
  setSaving(true);
  setIsProcessing(true);

  const selectedRowIds = Object.keys(selectedRows).filter(id => selectedRows[id]);
  const updates = selectedRowIds.map(id => ({
      id,
      fields: stateFieldSelection,
      client_name: selectedClient,
      state: stateUpdateChoice
  }));

  try {
      await axios.put(`${process.env.REACT_APP_API_URL}/qfe-update`, updates, {
          headers: { Authorization: `Bearer ${token}` },
      });

      // Update the front-end state directly
      setData((prevData) =>
          prevData.map((row) => {
              if (selectedRowIds.includes(row.id)) {
                  const updatedFields = {};
                  stateFieldSelection.forEach((field) => {
                      updatedFields[`${field}_state`] = stateUpdateChoice;
                  });
                  return { ...row, ...updatedFields };
              }
              return row;
          })
      );

      setOriginalData((prevOriginalData) =>
          prevOriginalData.map((row) => {
              if (selectedRowIds.includes(row.id)) {
                  const updatedFields = {};
                  stateFieldSelection.forEach((field) => {
                      updatedFields[`${field}_state`] = stateUpdateChoice;
                  });
                  return { ...row, ...updatedFields };
              }
              return row;
          })
      );

      setShowStateUpdatePopup(false);
  } catch (error) {
      console.error(`Error updating fields to ${stateUpdateChoice}:`, error);
  } finally {
      setSaving(false);
      setIsProcessing(false);
  }
};


  const selectAllFields = () => {
    setFieldSelection(qfeColumns);
};

const deselectAllFields = () => {
  setFieldSelection([]);
};


  const handleGenerate = async () => {
    setShowConfirmationPopup(false);
    setShowFieldSelectionPopup(false);
    setGenerating(true);
    setDisableEditing(true);
    setSaving(true);

    const selectedIds = Object.keys(selectedRows).filter(id => selectedRows[id]);
    setGeneratingRows(selectedIds);
    setProcessedCount(0); // Reset processed count
    setTotalToProcess(selectedIds.length); // Set total items to process

    try {
        const requestData = {
            selected_ids: selectedIds,
            selected_fields: fieldSelection,
            client_name: selectedClient, // Add selected client name
            prompt_configuration: selectedPromptConfiguration // Add selected prompt configuration
        };

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-qfe-fields`, requestData, {
            headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.message === 'Not enough credits') {
            setPromptConfigMessageType('error');
            setPromptConfigMessage('Not enough credits to generate items. Please purchase more tokens.');
        } else {
            const results = response.data.results;
            const newCreditBalance = response.data.new_credit_balance;

            // Update the credit balance in the frontend state
            setCurrentBalance(newCreditBalance);

            results.forEach(result => {
                if (result.status === 'success') {
                    setData(prevData => prevData.map(row => row.id === result.id ? { ...row, ...result.data } : row));
                    setProcessedCount(prev => prev + 1); // Update processed count
                }
            });

            setPromptConfigMessageType('success');
            setPromptConfigMessage('QFE fields generated successfully');
        }
    } catch (err) {
        console.error('Failed to generate QFE fields', err);
        if (err.response && err.response.data && err.response.data.message) {
            setPromptConfigMessageType('error');
            setPromptConfigMessage(err.response.data.message);
        } else {
            setPromptConfigMessageType('error');
            setPromptConfigMessage('Failed to generate QFE fields');
        }
    } finally {
        setSaving(false);
        setGenerating(false);
        setDisableEditing(false);
        setGeneratingRows([]);
        setFieldSelection([]);
    }
};
  

  const handleSelectAllRows = () => {
    if (selectedRowCount === data.length) {
      setSelectedRows({});
    } else {
      const newSelectedRows = {};
      data.forEach(row => {
        newSelectedRows[row.id] = true;
      });
      setSelectedRows(newSelectedRows);
    }
  };
  

  const handleGenerateItems = () => {
    const selectedIds = Object.keys(selectedRows).filter(id => selectedRows[id]);
    if (selectedIds.length > 0) {
      setSelectedRowIds(selectedIds);
      setShowFieldSelectionPopup(true); // Show the field selection popup
    }
};

const handleFieldSelectionChange = (field) => {
  setFieldSelection(prevSelection => {
    if (prevSelection.includes(field)) {
      return prevSelection.filter(item => item !== field);
    } else {
      return [...prevSelection, field];
    }
  });
};


const handleSavePopupChanges = async () => {
  setActiveProcesses(prev => prev + 1); // Increment active process count
  setPopupSaving(true);
  const updates = {};

  for (let key of Object.keys(popupRowData)) {
    if (key.startsWith('qfe_')) {
      updates[key] = popupRowData[key];
    }
  }

  try {
    await axios.put(`${process.env.REACT_APP_API_URL}/qpe-data/${popupRowData.id}`, { ...updates, client_name: selectedClient }, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setData(prevData => prevData.map(row => 
      row.id === popupRowData.id ? { ...row, ...updates } : row
    ));

    setOriginalData(prevOriginalData => prevOriginalData.map(row =>
      row.id === popupRowData.id ? { ...row, ...updates } : row
    ));

    setHasUnsavedChanges(false);
    setSaveSuccess(true);
    setTimeout(() => setSaveSuccess(false), 3000);
  } catch (error) {
    console.error('There was an error updating the data!', error);
  } finally {
    setPopupSaving(false);
    setActiveProcesses(prev => prev - 1); // Decrement active process count
  }
};


  const handlePublishSupplementaryFeed = async () => {
    setPublishing(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-supplementary-feed?client_name=${selectedClient}`, null, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const feedUrl = response.data.feed_url; // Extract the feed URL from the response
      setFeedUrl(feedUrl); // Set the feed URL in the state

      setPromptConfigMessageType('success');
      setPromptConfigMessage('Supplementary feed published successfully');
    } catch (error) {
      console.error('Error generating supplementary feed:', error);
      setPromptConfigMessageType('error');
      setPromptConfigMessage('Error publishing supplementary feed');
    } finally {
      setPublishing(false);
    }
  };

  const unselectedColumnsRegular = unselectedColumns.filter(col => !col.startsWith('qfe_'));
  const unselectedColumnsQfe = unselectedColumns.filter(col => col.startsWith('qfe_'));

  const selectedRowCount = Object.keys(selectedRows).filter(id => selectedRows[id]).length;

  return (
    <div id="app-container" className={publishing ? 'disabled' : ''}>
      <GlobalLoadingIndicator loading={globalLoading} />
      <div className="top-bar">
        <div className="top-bar-left">
          <img src="https://s3.eu-north-1.amazonaws.com/static.s360digital.com/s360-logo-white-1.svg" alt="logo" className="logo" />
          <h1>Quantum Feed Engine - Text Based Attributes</h1>
        </div>
        <div className="top-bar-right">
        <button className="back-button" onClick={() => navigate(isAdmin ? '/admin' : '/client')}>Back</button>
          <button className="config-button" onClick={() => navigate('/prompt-builder')}>Prompt Builder</button>
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      </div>
  
      <div className="main-content">
        {clients.length > 0 && (
          <div className="client-selection" style={{ display: 'flex', alignItems: 'center' }}>
            <label htmlFor="client-select">Select Client:</label>
            <select id="client-select" value={selectedClient} onChange={handleClientChange} style={{ marginRight: '10px' }}>
              <option value="" disabled>Select a client</option>
              {clients.map((client) => (
                <option key={client} value={client}>{client}</option>
              ))}
            </select>
            <div className="client-balance" style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
              <i className="fas fa-coins" style={{ marginRight: '5px' }}></i>
              <p style={{ margin: 0 }}>{currentBalance}</p>
            </div>
            <button 
              className="fetch-data-button" 
              onClick={() => fetchLatestData(selectedClient)} 
              disabled={xmlLoading || !selectedClient}
            >
              {xmlLoading ? 'Loading...' : 'Fetch Latest Data'}
            </button>
          </div>
        )}



        <div className="column-section">
          <div className="list-container">
            <div className="scrollable-list-container">
              <h3>Current XML feed</h3>
              <div className="scrollable-list">
                <ul>
                  {unselectedColumnsRegular.map((col) => (
                    <li 
                      key={col} 
                      className={selectedColumnItems.includes(col) ? 'selected' : ''} 
                      onClick={(event) => handleItemClick(col, 'unselected', event)}
                      style={{ pointerEvents: savingCells.length > 0 ? 'none' : 'auto' }}
                    >
                      {col}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="scrollable-list-container">
              <h3>QFE Supplementary Feed</h3>
              <div className="scrollable-list">
                <ul>
                  {unselectedColumnsQfe.map((col) => (
                    <li 
                      key={col} 
                      className={selectedColumnItems.includes(col) ? 'selected' : ''} 
                      onClick={(event) => handleItemClick(col, 'unselected', event)}
                      style={{ pointerEvents: savingCells.length > 0 ? 'none' : 'auto' }}
                    >
                      {col} <img src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/s360logo.png" alt="AI Generated" className="ai-generated-icon" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="scrollable-list-container">
              <h3>Selected Columns (Result)</h3>
              <div className="scrollable-list">
                <ul>
                  {selectedColumns.map((col, index) => (
                    <li 
                      key={col} 
                      className={selectedColumnItems.includes(col) ? 'selected' : ''} 
                      draggable
                      onDragStart={() => onDragStart(index)}
                      onDragOver={(e) => {
                        e.preventDefault();
                        onDragOver(index);
                      }}
                      onDrop={onDragEnd}
                      onClick={(event) => handleItemClick(col, 'selected', event)}
                      style={{ pointerEvents: savingCells.length > 0 ? 'none' : 'auto' }}
                    >
                      {col}
                      {col.startsWith('qfe_') && <img src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/s360logo.png" alt="AI Generated" className="ai-generated-icon" />}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="button-group-column-selection">
            <button onClick={moveToSelected} disabled={disableEditing || savingCells.length > 0 || !selectedClient}>Add Selected Columns</button>
            <button onClick={moveToUnselected} disabled={disableEditing || savingCells.length > 0 || !selectedClient}>Remove Selected Columns</button>
          </div>
        </div>


        <div className={`filter-section ${filterSectionVisible ? '' : 'hidden'}`}>
  <div className="filter-box">
    {filters.map((filter, index) => (
      <div key={index} className="filter-group">
        {index > 0 && (
          <select
            value={filter.logic}
            onChange={(e) => handleFilterChange(index, 'logic', e.target.value)}
          >
            <option value="AND">AND</option>
            <option value="OR">OR</option>
          </select>
        )}
        <select
          value={filter.key}
          onChange={(e) => handleFilterChange(index, 'key', e.target.value)}
        >
          <option value="">Select a column</option>
          {sortedColumns.map((col) => (
            <option key={col} value={col}>{col}</option>
          ))}
        </select>
        <select
          value={filter.operator}
          onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
        >
          <option value="equals">is equal to</option>
          <option value="not_equals">is not equal to</option>
          <option value="contains">contains</option>
          <option value="not_contains">doesn't contain</option>
          <option value="is_blank">is blank</option>
          <option value="is_not_blank">is not blank</option>
          <option value="starts_with">starts with</option>
          <option value="not_starts_with">doesn't start with</option>
          <option value="ends_with">ends with</option>
          <option value="not_ends_with">doesn't end with</option>
          <option value="greater_than">is greater than</option>
          <option value="less_than">is less than</option>
          <option value="greater_or_equal">is greater or equal to</option>
          <option value="less_or_equal">is less or equal to</option>
          <option value="length_equals">length equals to</option>
          <option value="length_greater">length longer than</option>
          <option value="length_less">length shorter than</option>
          <option value="matches_regexp">matches regexp</option>
          <option value="not_matches_regexp">doesn't match regexp</option>
        </select>
        <input
          type="text"
          value={filter.value}
          onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleApplyFilter();
            }
          }}
        />
        <button onClick={() => handleRemoveFilter(index)} className="filter-remove-button">
          <i className="fas fa-times"></i>
        </button>
      </div>
    ))}
    {showPastedIdsBox && (
      <div className="pasted-ids-filter">
        <label htmlFor="pasted-ids">Filter by multiple IDs:</label>
        <textarea
          id="pasted-ids"
          value={pastedIds}
          onChange={(e) => setPastedIds(e.target.value)}
          placeholder="Paste IDs here, separated by commas, spaces, or newlines"
        />
      </div>
    )}
    <div className="filter-actions">
    <label>
  <select value={orderBy} onChange={handleOrderByChange} className="order-by-select">
    <option value="" disabled hidden>Order By...</option>
    <option value="id">id</option>
    <option value="title">Title</option>
    <option value="item_group_id">Item Group ID</option>
    <option value="description">Description</option>
    <option value="product_type">Product Type</option>
    <option value="brand">Brand</option>
    <option value="color">Color</option>
    <option value="gender">Gender</option>
    <option value="pattern">Pattern</option>
    <option value="material">Material</option>
    <option value="age_group">Age Group</option>
    <option value="size">Size</option>
    <option value="product_detail">Product Detail</option>
    <option value="google_product_category">Google Product Category</option>
    <option value="product_highlight1">Product Highlight 1</option>
    <option value="product_highlight2">Product Highlight 2</option>
    <option value="product_highlight3">Product Highlight 3</option>
    <option value="product_highlight4">Product Highlight 4</option>
    <option value="product_highlight5">Product Highlight 5</option>
    <option value="qfe_title">QFE Title</option>
    <option value="qfe_description">QFE Description</option>
    <option value="qfe_product_type">QFE Product Type</option>
    <option value="qfe_brand">QFE Brand</option>
    <option value="qfe_color">QFE Color</option>
    <option value="qfe_gender">QFE Gender</option>
    <option value="qfe_pattern">QFE Pattern</option>
    <option value="qfe_material">QFE Material</option>
    <option value="qfe_age_group">QFE Age Group</option>
    <option value="qfe_size">QFE Size</option>
    <option value="qfe_hex">QFE Hex</option>
    <option value="qfe_product_detail">QFE Product Detail</option>
    <option value="qfe_google_product_category">QFE Google Product Category</option>
    <option value="qfe_product_highlight1">QFE Product Highlight 1</option>
    <option value="qfe_product_highlight2">QFE Product Highlight 2</option>
    <option value="qfe_product_highlight3">QFE Product Highlight 3</option>
    <option value="qfe_product_highlight4">QFE Product Highlight 4</option>
    <option value="qfe_product_highlight5">QFE Product Highlight 5</option>
    <option value="other_feed_values">Other Feed Values</option>
    {/* Add other options as needed */}
  </select>
</label>

      <label>
        <select value={orderDirection} onChange={handleOrderDirectionChange} className="order-by-select">
          <option value="asc">Asc</option>
          <option value="desc">Desc</option>
        </select>
      </label>
      <button onClick={handleReplaceAction} className="filter-add-button">
        <i className="fas fa-exchange-alt"></i>
      </button>
      <button onClick={() => setShowPastedIdsBox(!showPastedIdsBox)} className="filter-add-button">
        <i className="fas fa-list"></i>
      </button>
      <button onClick={handleAddFilter} className="filter-add-button">
        <i className="fas fa-plus"></i>
      </button>

      <button onClick={handleApplyFilter} className="filter-action-button">Apply Filter</button>
    </div>
  </div>
</div>



        <div className="table-controls">
          <div className="button-group">
            <button onClick={handleGenerateItems} disabled={disableEditing || selectedRowCount === 0 || generating || savingCells.length > 0}>
              Generate ({selectedRowCount})
            </button>
            <button onClick={handleStateUpdatePopup} disabled={disableEditing || selectedRowCount === 0 || savingCells.length > 0}>
              Change Status ({selectedRowCount})
            </button>
            <button onClick={handlePublishSupplementaryFeed} disabled={disableEditing || publishing || savingCells.length > 0 || !selectedClient}>
              {publishing ? <img src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/loading.gif" alt="loading" className="loading-gif" /> : 'Publish feed'}
            </button>
            <button
              onClick={handleOpenFeedPopup}
              disabled={!selectedClient}
              className={`filter-toggle-button ${!selectedClient ? 'disabled' : ''}`}
              style={{ padding: '0.5rem' }}
            >
              <i className="fas fa-eye"></i>
            </button>
          </div>
          <div className="right-controls">
            <div className="pagination-controls">
              <label>
                Page Size:
                <select value={perPage} onChange={handlePerPageChange}>
                  <option value={10}>10</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                  <option value={2000}>2000</option>
                </select>
              </label>
              <div>
                Page <input 
                  type="number" 
                  value={page} 
                  onChange={(e) => {
                    const newPage = parseInt(e.target.value, 10);
                    if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
                      handlePageChange(newPage);
                    }
                  }} 
                  onBlur={(e) => {
                    const newPage = parseInt(e.target.value, 10);
                    if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
                      handlePageChange(newPage);
                    } else {
                      e.target.value = page; // reset to current page if invalid
                    }
                  }} 
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const newPage = parseInt(e.target.value, 10);
                      if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
                        handlePageChange(newPage);
                      } else {
                        e.target.value = page; // reset to current page if invalid
                      }
                    }
                  }}
                  style={{ width: '50px', textAlign: 'center' }}
                /> of {totalPages} (#{totalItems})
              </div>
              <button onClick={() => handlePageChange(page - 1)} disabled={page <= 1}>
                <i className="fas fa-chevron-left"></i>
              </button>
              <button onClick={() => handlePageChange(page + 1)} disabled={page >= totalPages}>
                <i className="fas fa-chevron-right"></i>
              </button>

            </div>
          </div>
        </div>

        <div className="table-container full-width-table-container">
  <button className="filter-toggle-button" onClick={() => setFilterSectionVisible(!filterSectionVisible)}>
    <i className={`fas fa-filter ${filterSectionVisible ? 'active' : ''}`}></i>
  </button>
  <button
    className="filter-toggle-button"
    onClick={handleDownloadAllData}
    disabled={isDownloading}
  >
    <i className="fas fa-download download-csv-icon"></i>
  </button>

  {data.length > 0 && selectedColumns.length > 0 ? (
    <table className="full-width-table">
      <thead>
        <tr>
          <th style={{ width: '30px' }}></th> {/* Empty header cell for the "pencil" icon column */}
          <th style={{ width: '40px', textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={selectedRowCount === data.length && data.length > 0}
              onChange={handleSelectAllRows}
              disabled={disableEditing || savingCells.length > 0 || data.length === 0}
            />
          </th>
          {selectedColumns.map((key, index) => (
            <th
              key={key}
              style={{ width: key === 'image_link' ? '60px' : columnWidths[index] || 200, position: 'relative' }}
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <span>{key}</span>
                {key.startsWith('qfe_') && (
                  <img
                    src="https://s3.eu-north-1.amazonaws.com/static.s360digital.com/s360-logo-white-1.svg"
                    alt="AI Generated"
                    className="ai-generated-icon"
                  />
                )}
                <div
                  style={{
                    width: '10px',
                    height: '100%',
                    cursor: 'col-resize',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    backgroundColor: 'transparent',
                  }}
                  onMouseDown={(e) => handleResizeStart(index, e)}
                />
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id} style={{ backgroundColor: selectedRows[row.id] ? '#d3d3d3' : 'transparent' }}>
            <td style={{ width: '30px', textAlign: 'center' }}>
              <span className="icon-button" onClick={() => setPopupRowData(row)}>
                &#x270E; {/* Edit/Modify icon (pencil icon) */}
              </span>
            </td>
            <td style={{ width: '40px', textAlign: 'center' }}>
              <input
                type="checkbox"
                checked={!!selectedRows[row.id]}
                onChange={() => handleRowSelection(row.id)}
                disabled={disableEditing || savingCells.length > 0 || generatingRows.includes(row.id)}
              />
            </td>
            {selectedColumns.map((key, index) => {
              const stateKey = `${key}_state`;
              const backgroundColor = getBackgroundColor(row[stateKey], selectedRows[row.id], generatingRows.includes(row.id));
              const columnWidth = key === 'image_link' ? '60px' : columnWidths[index] || 200;

              return (
                <td
                  key={key}
                  style={{ backgroundColor, width: columnWidth, position: 'relative' }}
                  className={savingCells.some(cell => cell.id === row.id && cell.key === key) ? 'saving' : ''}
                >
                  {key === 'image_link' ? (
                    <img
                      src={row[key]}
                      alt="Image"
                      style={{ width: '100%', maxWidth: '60px', maxHeight: '50px' }}
                    />
                  ) : (
                    key.includes('qfe_') ? (
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <textarea
                          value={row[key]}
                          onChange={(e) => handleChange(row.id, key, e.target.value)}
                          onBlur={(e) => handleBlur(row.id, key, e.target.value)}
                          disabled={disableEditing || savingCells.some(cell => cell.id === row.id && cell.key === key) || generatingRows.includes(row.id)}
                          className="editable-textarea"
                          style={{
                            width: '100%', // Set textarea width to 100%
                            height: '100%',
                            resize: 'both', // Allow both horizontal and vertical resizing
                            boxSizing: 'border-box',
                          }}
                          onMouseDown={(e) => e.stopPropagation()} // Prevent triggering column resize
                        />
                        <div
                          style={{
                            width: '10px',
                            height: '100%',
                            cursor: 'col-resize',
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            backgroundColor: 'transparent',
                          }}
                          onMouseDown={(e) => handleResizeStart(index, e)}
                        />
                      </div>
                    ) : (
                      row[key]
                    )
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>No data available</p>
  )}
</div>

</div>

     
     
{showStateUpdatePopup && (
        <div className="popup-overlay" onClick={handlePopupOverlayClick}>
          <div className="popup-inner" onClick={e => e.stopPropagation()}>
            <h2>Activate or Deactivate items</h2>
            <div className="button-group">
              <button onClick={() => setStateFieldSelection(qfeColumns)} disabled={isProcessing}>Select All</button>
              <button onClick={() => setStateFieldSelection([])} disabled={isProcessing}>Deselect All</button>
            </div>
            <ul className="field-list">
              {qfeColumns.map((col) => (
                <li
                  key={col}
                  onClick={() => handleStateFieldSelectionChange(col)}
                  className={stateFieldSelection.includes(col) ? 'selected' : ''}
                >
                  <label htmlFor={col}>{col}</label>
                </li>
              ))}
            </ul>
            <div className="radio-group">
              <label className="radio-option">
                <input
                  type="radio"
                  value="active"
                  checked={stateUpdateChoice === 'active'}
                  onChange={() => handleStateUpdateChoiceChange('active')}
                />
                <span className="radio-label">Activate</span>
              </label>
              <label className="radio-option">
                <input
                  type="radio"
                  value="draft"
                  checked={stateUpdateChoice === 'draft'}
                  onChange={() => handleStateUpdateChoiceChange('draft')}
                />
                <span className="radio-label">Deactivate</span>
              </label>
            </div>
            <div className="button-group">
              <button
                onClick={handleStateUpdate}
                disabled={stateFieldSelection.length === 0 || isProcessing}
              >
                {stateUpdateChoice === 'active' ? 'Activate' : 'Deactivate'} {selectedRowCount} Items
              </button>
              <button onClick={() => setShowStateUpdatePopup(false)} disabled={isProcessing}>Close</button>
            </div>
          </div>
        </div>
      )}


      {showFeedPopup && (
              <div className="feed-popup-overlay" onClick={handlePopupOverlayClick}>
                <div className="feed-popup-inner">
                  <button className="feed-popup-close-button" onClick={handleCloseFeedPopup}>×</button>
                  <h2>Open Feeds</h2>
                  <div className="feed-popup-buttons">
                    <button onClick={() => window.open(`https://storage.googleapis.com/quantum-feed-engine/${selectedClient}/original_xml_original_qfe.xml`, '_blank')}>Orignal XML + QFE fields</button>
                    <button onClick={() => window.open(`https://storage.googleapis.com/quantum-feed-engine/${selectedClient}/original_qfe.xml`, '_blank')}>Only QFE fields</button>
                    <button onClick={() => window.open(`https://storage.googleapis.com/quantum-feed-engine/${selectedClient}/modified_qfe.xml`, '_blank')}>Only QFE fields, but without QFE names</button>
                  </div>
                </div>
              </div>
            )}


      {showFieldSelectionPopup && (
              <div className="popup-overlay" onClick={handlePopupOverlayClick}>
                <div className="popup-inner" onClick={e => e.stopPropagation()}>
                  <h2>Select QFE Fields to Generate</h2>
                  <div className="button-group">
                    <button onClick={selectAllFields}>Select All</button>
                    <button onClick={deselectAllFields}>Deselect All</button>
                  </div>
                  <ul className="field-list">
                    {qfeColumns.map((col) => (
                      <li
                        key={col}
                        onClick={() => handleFieldSelectionChange(col)}
                        className={fieldSelection.includes(col) ? 'selected' : ''}
                      >
                        <label htmlFor={col}>{col}</label>
                      </li>
                    ))}
                  </ul>
                  <div className="prompt-selection">
                    <label htmlFor="prompt-select">Select Prompt Configuration:</label>
                    <select id="prompt-select" value={selectedPromptConfiguration} onChange={handlePromptConfigurationChange}>
                      <option value="" disabled>Select a configuration</option>
                      {promptConfigurations.map((config) => (
                        <option key={config.combination_id} value={config.combination_id}>{config.combination_name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="button-group">
                  <button
                    onClick={handleConfirmFieldSelection}
                    disabled={fieldSelection.length === 0 || selectedPromptConfiguration === '' || currentBalance < generationCost}
                  >
                    {currentBalance < generationCost ? 'Not enough credits' : 'Generate'}
                  </button>


                    <button onClick={() => setShowFieldSelectionPopup(false)}>Close</button>
                  </div>
                </div>
              </div>
            )}

    {showConfirmationPopup && (
        <div className="popup-overlay">
          <div className="popup-inner" onClick={e => e.stopPropagation()}>
            <h2>Confirm Generation</h2>
            <p>This will cost {generationCost} credits. Are you sure you want to continue?</p>
            <div className="button-group">
            <button
              onClick={handleGenerate}
              disabled={currentBalance < generationCost}
            >
              {currentBalance < generationCost ? 'Not enough credits' : 'Yes'}
            </button>


              <button onClick={() => setShowConfirmationPopup(false)}>No</button>
            </div>
          </div>
        </div>
      )}



{popupRowData && (
        <div className="popup-overlay new-popup-overlay" onClick={handlePopupOverlayClick}>
          <div className="popup-inner new-popup-inner" onClick={e => e.stopPropagation()}>
            <button className="new-popup-close-button" onClick={handlePopupClose}>×</button>
            <div className="popup-header new-popup-header">
              <div className="popup-preview new-popup-preview">
                <div className="popup-preview-image new-popup-preview-image">
                  {popupRowData.image_link ? (
                    <img src={popupRowData.image_link} alt={popupRowData.title} />
                  ) : (
                    <p>No image available</p>
                  )}
                </div>
                <div className="popup-preview-details new-popup-preview-details">
                  <p><strong>ID:</strong> {popupRowData.id}</p>
                  <p><strong>Title:</strong> {popupRowData.title}</p>
                  <p><strong>Brand:</strong> {popupRowData.brand}</p>
                </div>
              </div>
              <div className="popup-controls new-popup-controls">
                <button
                  className="popup-save-button new-popup-save-button"
                  onClick={handleSavePopupChanges}
                  disabled={popupSaving || activeProcesses > 0 || !hasUnsavedChanges}
                >
                  {popupSaving ? (
                    <img src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/loading.gif" alt="loading" className="loading-img" />
                  ) : (
                    'Save Changes'
                  )}
                </button>
              </div>
            </div>
            <div className="field-selection-collapsible">
              <input type="checkbox" id="field-selection-toggle" className="field-selection-toggle" />
              <label htmlFor="field-selection-toggle" className="field-selection-label">
                Generate QFE Fields
                <span className="collapsible-icon"></span>
              </label>
              <div className="field-selection-content">
                <div className="button-group-inline">
                  <button onClick={() => setPopupFieldSelection(qfeColumns)}>Select All</button>
                  <button onClick={() => setPopupFieldSelection([])}>Deselect All</button>
                </div>
                <div className="field-list">
                  {qfeColumns.map((col) => (
                    <div key={col} className={`field-item ${popupFieldSelection.includes(col) ? 'selected' : ''}`}>
                      <input
                        type="checkbox"
                        id={`field-${col}`}
                        checked={popupFieldSelection.includes(col)}
                        onChange={() => handlePopupFieldSelectionChange(col)}
                      />
                      <label htmlFor={`field-${col}`}>{col}</label>
                    </div>
                  ))}
                </div>
                <div>
                  <label htmlFor="prompt-select">Select Prompt Configuration:</label>
                  <select id="prompt-select" value={selectedPromptConfiguration} onChange={(e) => setSelectedPromptConfiguration(e.target.value)}>
                    <option value="" disabled>Select a configuration</option>
                    {promptConfigurations.map((config) => (
                      <option key={config.combination_id} value={config.combination_id}>{config.combination_name}</option>
                    ))}
                  </select>
                </div>
                <button
                  className="popup-generate-button new-popup-generate-button"
                  onClick={handleGenerateItemInPopup}
                  disabled={generating || savingCells.length > 0 || popupSaving || activeProcesses > 0 || popupFieldSelection.length === 0 || selectedPromptConfiguration === ''}
                >
                  {generating ? (
                    <img src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/loading.gif" alt="loading" className="loading-img" />
                  ) : (
                    'Generate QFE Fields'
                  )}
                </button>
              </div>
            </div>
            <div className="row-details new-row-details">
              {originalData.length > 0 && Object.keys(originalData[0]).filter(key => !key.endsWith('_state') && !key.startsWith('qfe_') && key !== 'clientname' && key !== 'id' && key !== 'qfe_id' && key !== 'image_link' && key !== 'item_group_id' && key !== 'other_feed_values').map((key) => {
                const qfeKey = `qfe_${key}`;
                const stateKey = `${qfeKey}_state`;
                const backgroundColor = getBackgroundColor(popupRowData[stateKey], false, false);
                const isLoading = savingCells.some(cell => cell.id === popupRowData.id && cell.key === qfeKey);

                return (
                  <div key={key} className="row-detail new-row-detail">
                    <div className="row-detail-left new-row-detail-left">
                      <strong>{key}</strong>: {popupRowData[key] || 'This attribute doesn\'t appear in your feed'}
                    </div>
                    <div className="row-detail-right new-row-detail-right">
                      <strong>{qfeKey}</strong>:
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <textarea
                          value={popupRowData[qfeKey] || ''}
                          onChange={(e) => handlePopupChange(popupRowData.id, qfeKey, e.target.value)}
                          onBlur={(e) => handleBlur(popupRowData.id, qfeKey, e.target.value, true)} // Pass true to indicate the popup context
                          className={`popup-editable-textarea new-popup-editable-textarea ${isLoading ? 'saving' : ''}`}
                          disabled={isLoading || generating || popupSaving} // Disable the textarea if it's loading or generating
                          style={{
                            backgroundColor,
                            width: '100%'
                          }}
                        />
                        {isLoading && (
                          <img
                            src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/loading.gif"
                            alt="loading"
                            className="loading-gif"
                            style={{ position: 'absolute', right: '10px', bottom: '10px' }}
                          />
                        )}
                        <button
                          onClick={() => handleToggleState(popupRowData.id, qfeKey)}
                          className={`toggle-button new-toggle-button ${popupRowData[stateKey] === 'active' ? 'deactivate' : 'active'} ${loadingStates[`${popupRowData.id}_${stateKey}`] ? 'loading' : ''}`}
                          disabled={loadingStates[`${popupRowData.id}_${stateKey}`] || generating || popupSaving} // Disable the button if it's loading or generating
                        >
                          {loadingStates[`${popupRowData.id}_${stateKey}`] ? (
                            <img
                              src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/loading.gif"
                              alt="loading"
                              className="loading-img"
                            />
                          ) : (
                            popupRowData[stateKey] === 'active' ? 'Deactivate' : 'Activate'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              {qfeColumns.filter(qfeKey => !originalData[0].hasOwnProperty(qfeKey.replace('qfe_', ''))).map((qfeKey) => {
                const stateKey = `${qfeKey}_state`;
                const backgroundColor = getBackgroundColor(popupRowData[stateKey], false, false);
                const isLoading = savingCells.some(cell => cell.id === popupRowData.id && cell.key === qfeKey);

                return (
                  <div key={qfeKey} className="row-detail new-row-detail">
                    <div className="row-detail-left new-row-detail-left">
                      <strong>{qfeKey.replace('qfe_', '')}</strong>: This attribute doesn't appear in your feed
                    </div>
                    <div className="row-detail-right new-row-detail-right">
                      <strong>{qfeKey}</strong>:
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <textarea
                          value={popupRowData[qfeKey] || ''}
                          onChange={(e) => handlePopupChange(popupRowData.id, qfeKey, e.target.value)}
                          onBlur={(e) => handleBlur(popupRowData.id, qfeKey, e.target.value, true)} // Pass true to indicate the popup context
                          className={`popup-editable-textarea new-popup-editable-textarea ${isLoading ? 'saving' : ''}`}
                          disabled={isLoading || generating || popupSaving} // Disable the textarea if it's loading or generating
                          style={{
                            backgroundColor,
                            width: '100%'
                          }}
                        />
                        {isLoading && (
                          <img
                            src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/loading.gif"
                            alt="loading"
                            className="loading-gif"
                            style={{ position: 'absolute', right: '10px', bottom: '10px' }}
                          />
                        )}
                        <button
                          onClick={() => handleToggleState(popupRowData.id, qfeKey)}
                          className={`toggle-button new-toggle-button ${popupRowData[stateKey] === 'active' ? 'deactivate' : 'active'} ${loadingStates[`${popupRowData.id}_${stateKey}`] ? 'loading' : ''}`}
                          disabled={loadingStates[`${popupRowData.id}_${stateKey}`] || generating || popupSaving} // Disable the button if it's loading or generating
                        >
                          {loadingStates[`${popupRowData.id}_${stateKey}`] ? (
                            <img
                              src="https://storage.googleapis.com/quantum-feed-engine/qfe_toolbox/media/loading.gif"
                              alt="loading"
                              className="loading-img"
                            />
                          ) : (
                            popupRowData[stateKey] === 'active' ? 'Deactivate' : 'Activate'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {hasUnsavedChanges && (
              <div className="popup-unsaved elegant-indicator">
                You have unsaved changes
              </div>
            )}
            {saveSuccess && (
              <div className="popup-success">
                Changes saved successfully!
              </div>
            )}
          </div>
          {showWarning && (
            <div className="popup-warning-overlay">
              <div className="popup-warning-inner">
                <p>You have unsaved changes. Are you sure you want to close?</p>
                <button onClick={confirmClosePopup}>Yes, Close</button>
                <button onClick={() => setShowWarning(false)}>Cancel</button>
              </div>
            </div>
          )}
  </div>
)}





{replacePopupVisible && (
  <div className="popup-overlay" onClick={() => setReplacePopupVisible(false)}>
    <div className="replace-popup-container" onClick={(e) => e.stopPropagation()}>
      <h2 className="replace-popup-header">Replace</h2>
      <div className="replace-popup-body">
        <div className="replace-input-group">
          <label>Replace:</label>
          <input
            type="text"
            placeholder="Original value"
            value={replaceOriginalValue}
            onChange={(e) => setReplaceOriginalValue(e.target.value)}
          />
          <span className="with-label">with</span>
          <input
            type="text"
            placeholder="New value"
            value={replaceNewValue}
            onChange={(e) => setReplaceNewValue(e.target.value)}
          />
          <span className="with-label">in</span>
          <select
            id="replace-column"
            value={replaceColumn}
            onChange={(e) => setReplaceColumn(e.target.value)}
          >
            <option value="" disabled>Select a column</option>
            {qfeColumns.map((col) => (
              <option key={col} value={col}>
                {col}
              </option>
            ))}
          </select>
        </div>
        <div className="replace-case-sensitive">
          <label>Case Sensitive:</label>
          <label>
            <input
              type="radio"
              value={true}
              checked={isCaseSensitive === true}
              onChange={() => setIsCaseSensitive(true)}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              value={false}
              checked={isCaseSensitive === false}
              onChange={() => setIsCaseSensitive(false)}
            />
            No
          </label>
        </div>
      </div>
      <div className="replace-buttons">
        <span>{updatedRowCount > 0 ? `${updatedRowCount} rows updated` : ''}</span>
        <button
          onClick={handleReplaceConfirm}
          disabled={isProcessingReplace || !replaceColumn || !replaceOriginalValue || !replaceNewValue}
        >
          {isProcessingReplace ? <ClipLoader size={18} color={"#ffffff"} /> : "Replace"}
        </button>
      </div>
    </div>
  </div>
)}









    </div>

    
  );
};

export default QpeText;
