// src/components/Register.js

import React, { useState } from 'react';
import axios from 'axios';
import styles from './css/Register.module.css'; // Ensure the path is correct
import { FaTimes } from 'react-icons/fa';

const Register = ({ onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, { 
        username, 
        password, 
        admin_password: isAdmin ? adminPassword : null 
      });
      setMessage(response.data.message);
      if (response.data.success) {
        // Optionally, you can reset the form or perform other actions
        setUsername('');
        setPassword('');
        setAdminPassword('');
        setIsAdmin(false);
      }
    } catch (err) {
      setMessage(err.response?.data?.message || 'Registration failed.');
    }
  };

  return (
    <div className={styles.registerModal}>
      <div className={styles.registerContainer}>
        <h2>Register New User</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label>Username</label>
            <input 
              type="text" 
              placeholder="Username" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              required 
            />
          </div>
          <div className={styles.formGroup}>
            <label>Password</label>
            <input 
              type="password" 
              placeholder="Password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          <label className={styles.adminCheckboxLabel}>Should this account be admin?</label>
          <div className={`${styles.formGroup} ${styles.adminCheckboxContainer}`}>
            <input 
              type="checkbox" 
              checked={isAdmin} 
              onChange={(e) => setIsAdmin(e.target.checked)} 
            />
          </div>
          {isAdmin && (
            <div className={styles.formGroup}>
              <label>Please enter the secret key to create Admin Account:</label>
              <input 
                type="password" 
                placeholder="Admin Password" 
                value={adminPassword} 
                onChange={(e) => setAdminPassword(e.target.value)} 
                required={isAdmin}
              />
            </div>
          )}
          <button type="submit" className={styles.submitButton}>Register</button>
        </form>
        {message && (
          <p className={`${styles.message} ${message.toLowerCase().includes('success') ? styles.messageSuccess : styles.messageError}`}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default Register;
