// src/popups/PopupAddFeed.js

import React, { useState } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './css/PopupAddFeed.module.css';
import { FaSpinner, FaUpload } from 'react-icons/fa';

const PopupAddFeed = ({ token, onClose }) => {
  const [feedName, setFeedName] = useState('');
  const [feedNameError, setFeedNameError] = useState('');
  const [isCheckingFeedName, setIsCheckingFeedName] = useState(false);
  const [isFeedNameValid, setIsFeedNameValid] = useState(false);

  const [selectedOption, setSelectedOption] = useState('');
  const [selectedPim, setSelectedPim] = useState('');

  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [link, setLink] = useState('');
  const [linkError, setLinkError] = useState('');

  const [mappingFile, setMappingFile] = useState(null);
  const [mappingFileError, setMappingFileError] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMappingUploading, setIsMappingUploading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [isDataProvided, setIsDataProvided] = useState(false);
  const [isCheckingLink, setIsCheckingLink] = useState(false);

  const handleFeedNameBlur = async () => {
    if (!feedName.trim()) {
      setFeedNameError('Please enter a feed name.');
      setIsFeedNameValid(false);
      return;
    }
    setIsCheckingFeedName(true);
    setFeedNameError('');
    setSuccessMessage('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-client-name`,
        { clientName: feedName },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.isTaken) {
        setFeedNameError('This feed name is already taken.');
        setIsFeedNameValid(false);
      } else {
        setSuccessMessage('Feed name is available!');
        setIsFeedNameValid(true);
      }
    } catch (error) {
      setFeedNameError('Error checking feed name.');
      console.error('Error checking feed name:', error);
      setIsFeedNameValid(false);
    } finally {
      setIsCheckingFeedName(false);
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setFile(null);
    setLink('');
    setSelectedPim('');
    setFileError('');
    setLinkError('');
    setSuccessMessage('');
    setGeneralError('');
    setIsDataProvided(false);
  };

  const handlePimSelect = (pim) => {
    setSelectedPim(pim);
    setSuccessMessage('');
    setGeneralError('');
    setIsDataProvided(true);
  };

  const handleFileChange = async (e) => {
    setFileError('');
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 10 * 1024 * 1024) {
        setFileError('File size should not exceed 10MB.');
        setFile(null);
        return;
      }
      setFile(selectedFile);
      setIsSubmitting(true);
      try {
        const data = new FormData();
        data.append('file', selectedFile);
        data.append('client_name', feedName);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/upload-feed-file`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response.status === 200) {
          const gcsLink = response.data.link;
          setLink(gcsLink);
          setSuccessMessage('Data provided successfully!');
          setIsDataProvided(true);
        } else {
          setFileError('An error occurred while uploading the file.');
        }
      } catch (error) {
        setFileError('An error occurred while uploading the file.');
        console.error('Error uploading file:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
    setSuccessMessage('');
    setLinkError('');
    setIsDataProvided(false);
  };

  const handleLinkBlur = async () => {
    if (!link.trim()) {
      setLinkError('Please enter a link.');
      setIsDataProvided(false);
      return;
    }
    setIsCheckingLink(true);
    setLinkError('');
    setSuccessMessage('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-link`,
        { link },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.isAccessible) {
        setSuccessMessage('Data provided successfully!');
        setIsDataProvided(true);
      } else {
        setLinkError('The link is not accessible.');
        setIsDataProvided(false);
      }
    } catch (error) {
      setLinkError('An error occurred while checking the link.');
      setIsDataProvided(false);
    } finally {
      setIsCheckingLink(false);
    }
  };

  const handleMappingFileChange = (e) => {
    setMappingFileError('');
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setMappingFile(selectedFile);
    }
  };

  const handleMappingUpload = async () => {
    if (!mappingFile) {
      setMappingFileError('Please select a mapping file to upload.');
      return;
    }
    setIsMappingUploading(true);
    try {
      const data = new FormData();
      data.append('mapping_file', mappingFile);
      data.append('client_name', feedName);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload-mapping-file`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200) {
        setSuccessMessage('Mapping file uploaded successfully.');
        setMappingFile(null);
      } else {
        setMappingFileError('An error occurred while uploading the mapping file.');
      }
    } catch (error) {
      setMappingFileError('An error occurred while uploading the mapping file.');
      console.error('Error uploading mapping file:', error);
    } finally {
      setIsMappingUploading(false);
    }
  };

  const handleSubmit = async () => {
    if (!feedName.trim()) {
      setFeedNameError('Please enter a feed name.');
      return;
    }

    if (!isFeedNameValid) {
      setGeneralError('Please enter a valid feed name.');
      return;
    }

    if (!selectedOption) {
      setGeneralError('Please select a data type.');
      return;
    }

    if (selectedOption === 'PIM' && !selectedPim) {
      setGeneralError('Please select a PIM system.');
      return;
    }

    if (!isDataProvided && selectedOption !== 'PIM') {
      setGeneralError('Please provide your data.');
      return;
    }

    // Proceed to next steps or perform the final submission
    onClose();
  };

  const getAcceptFileTypes = () => {
    switch (selectedOption) {
      case 'XML':
        return '.xml';
      case 'CSV':
        return '.csv,.tsv,.xls,.xlsx';
      case 'JSON':
        return '.json';
      default:
        return '';
    }
  };

  const handleBackdropClick = (e) => {
    // If an input is focused, do not close the popup
    if (
      document.activeElement &&
      (document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA')
    ) {
      return;
    }
    onClose();
  };

  return (
    <div
      className={styles.modalBackdrop}
      onClick={handleBackdropClick}
      role="dialog"
      aria-modal="true"
      aria-labelledby="add-feed-modal-title"
    >
      <div
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <h2 id="add-feed-modal-title" className={styles.modalTitle}>
          Add New Feed
        </h2>

        {/* Step 1: Feed Name */}
        <div className={styles.stepContainer}>
          <div className={styles.stepHeader}>
            <div className={styles.stepNumber}>1</div>
            <h3 className={styles.stepTitle}>Enter Feed Name</h3>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="feedName" className={styles.label}>
              Feed Name:
              <div className={styles.tooltip}>
                <i className="fas fa-info-circle"></i>
                <span className={styles.tooltiptext}>
                  Enter a unique name for your feed.
                </span>
              </div>
            </label>
            <input
              type="text"
              id="feedName"
              value={feedName}
              onChange={(e) => {
                setFeedName(e.target.value);
                setIsFeedNameValid(false);
                setSuccessMessage('');
              }}
              onBlur={handleFeedNameBlur}
              className={styles.inputField}
              placeholder="Enter feed name"
            />
            {isCheckingFeedName && <FaSpinner className={styles.spinner} />}
            {feedNameError && (
              <p className={styles.errorMessage}>{feedNameError}</p>
            )}
            {successMessage && isFeedNameValid && (
              <p className={styles.successMessage}>{successMessage}</p>
            )}
          </div>
        </div>

        {/* Step 2: Select Data Type */}
        <div className={styles.stepContainer}>
          <div className={styles.stepHeader}>
            <div className={styles.stepNumber}>2</div>
            <h3 className={styles.stepTitle}>Select Data Type</h3>
          </div>
          <div className={styles.iconGroup}>
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'XML' ? styles.selectedOption : ''
              }`}
              onClick={() => handleOptionSelect('XML')}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/xml-icon.png"
                alt="XML"
                className={styles.dataTypeIcon}
              />
              <span>XML</span>
            </div>
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'CSV' ? styles.selectedOption : ''
              }`}
              onClick={() => handleOptionSelect('CSV')}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/csv-icon.png"
                alt="CSV"
                className={styles.dataTypeIcon}
              />
              <span>CSV/TSV/Excel</span>
            </div>
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'JSON' ? styles.selectedOption : ''
              }`}
              onClick={() => handleOptionSelect('JSON')}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/json-icon.png"
                alt="JSON"
                className={styles.dataTypeIcon}
              />
              <span>JSON</span>
            </div>
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'GoogleSheets' ? styles.selectedOption : ''
              }`}
              onClick={() => handleOptionSelect('GoogleSheets')}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/google-sheets-icon.png"
                alt="Google Sheets"
                className={styles.dataTypeIcon}
              />
              <span>Google Sheets</span>
            </div>
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'PIM' ? styles.selectedOption : ''
              }`}
              onClick={() => handleOptionSelect('PIM')}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/pim-icon.png"
                alt="PIM System"
                className={styles.dataTypeIcon}
              />
              <span>PIM System</span>
            </div>
          </div>
        </div>

        {/* Step 3: Provide Data */}
        <div className={styles.stepContainer}>
          <div className={styles.stepHeader}>
            <div className={styles.stepNumber}>3</div>
            <h3 className={styles.stepTitle}>
              {selectedOption === 'PIM'
                ? 'Select PIM System'
                : 'Provide Data'}
            </h3>
          </div>

          {/* For non-PIM options */}
          {selectedOption && selectedOption !== 'PIM' && (
            <>
              {/* For Google Sheets */}
              {selectedOption === 'GoogleSheets' ? (
                <div className={styles.formGroup}>
                  <label className={styles.label}>
                    Provide Google Sheet Link:
                  </label>
                  <input
                    type="text"
                    value={link}
                    onChange={handleLinkChange}
                    onBlur={handleLinkBlur}
                    className={styles.inputField}
                    placeholder="Enter Google Sheet link"
                    disabled={isSubmitting}
                  />
                  {isCheckingLink && <FaSpinner className={styles.spinner} />}
                  {linkError && (
                    <p className={styles.errorMessage}>{linkError}</p>
                  )}
                  {successMessage && isDataProvided && (
                    <p className={styles.successMessage}>{successMessage}</p>
                  )}
                </div>
              ) : (
                <div className={styles.dataOptions}>
                  <div className={styles.formGroup}>
                    <label className={styles.label}>Upload File:</label>
                    <input
                      type="file"
                      accept={getAcceptFileTypes()}
                      onChange={handleFileChange}
                      className={styles.inputField}
                      disabled={isSubmitting}
                    />
                    {fileError && (
                      <p className={styles.errorMessage}>{fileError}</p>
                    )}
                  </div>
                  <div className={styles.orDivider}>
                    <span>OR</span>
                  </div>
                  <div className={styles.formGroup}>
                    <label className={styles.label}>Provide Link:</label>
                    <input
                      type="text"
                      value={link}
                      onChange={handleLinkChange}
                      onBlur={handleLinkBlur}
                      className={styles.inputField}
                      placeholder="Enter data link"
                      disabled={isSubmitting}
                    />
                    {isCheckingLink && (
                      <FaSpinner className={styles.spinner} />
                    )}
                    {linkError && (
                      <p className={styles.errorMessage}>{linkError}</p>
                    )}
                  </div>
                </div>
              )}
              {/* Show success message if data is provided */}
              {successMessage && isDataProvided && (
                <p className={styles.successMessage}>{successMessage}</p>
              )}
            </>
          )}

          {/* For PIM options */}
          {selectedOption === 'PIM' && (
            <div className={styles.iconGroup}>
              <div
                className={`${styles.iconOption} ${
                  selectedPim === 'Shopify' ? styles.selectedOption : ''
                }`}
                onClick={() => handlePimSelect('Shopify')}
              >
                <img
                  src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/shopify-icon.png"
                  alt="Shopify"
                  className={styles.pimLogo}
                />
                <span>Shopify</span>
              </div>
              <div
                className={`${styles.iconOption} ${
                  selectedPim === 'Magento' ? styles.selectedOption : ''
                }`}
                onClick={() => handlePimSelect('Magento')}
              >
                <img
                  src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/magento-icon.png"
                  alt="Magento"
                  className={styles.pimLogo}
                />
                <span>Magento</span>
              </div>
              {/* Add other PIM options similarly */}
            </div>
          )}
        </div>

        {/* Step 4: Mapping */}
        {((isDataProvided && selectedOption !== 'PIM') ||
          (selectedOption === 'PIM' && selectedPim)) && (
          <div className={styles.stepContainer}>
            <div className={styles.stepHeader}>
              <div className={styles.stepNumber}>4</div>
              <h3 className={styles.stepTitle}>Mapping</h3>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Upload Mapping File:</label>
              <input
                type="file"
                accept=".json,.csv,.xlsx,.xls"
                onChange={handleMappingFileChange}
                className={styles.inputField}
                disabled={isMappingUploading}
              />
              {mappingFile && (
                <div className={styles.fileInfo}>
                  <FaUpload /> {mappingFile.name}
                  <button
                    className={styles.removeFileButton}
                    onClick={() => {
                      setMappingFile(null);
                    }}
                  >
                    Remove
                  </button>
                </div>
              )}
              {mappingFileError && (
                <p className={styles.errorMessage}>{mappingFileError}</p>
              )}
              <button
                onClick={handleMappingUpload}
                className={styles.mappingButton}
                disabled={isMappingUploading || !mappingFile}
              >
                {isMappingUploading
                  ? 'Uploading Mapping...'
                  : 'Upload Mapping File'}
              </button>
              {successMessage &&
                !isMappingUploading &&
                mappingFile &&
                !mappingFileError && (
                  <p className={styles.successMessage}>
                    Mapping file uploaded successfully!
                  </p>
                )}
            </div>
          </div>
        )}

        {/* General Error */}
        {generalError && <p className={styles.errorMessage}>{generalError}</p>}

        {/* Buttons */}
        <div className={styles.buttonGroup}>
          <button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className={styles.nextButton}
            disabled={isSubmitting || isMappingUploading}
          >
            {isSubmitting || isMappingUploading ? 'Submitting...' : 'Create Feed'}
          </button>
        </div>

        {/* Loading Indicator */}
        {(isSubmitting || isMappingUploading) && (
          <div className={styles.loadingOverlay}>
            <ClipLoader size={50} color={'#4758EB'} loading={true} />
            <p>{isSubmitting ? 'Processing...' : 'Uploading Mapping...'}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupAddFeed;
