// src/components/AdminDashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, NavLink } from 'react-router-dom';
import styles from './css/AdminDashboard.module.css';
import {
  FaSignOutAlt,
  FaArrowLeft,
  FaUserPlus,
  FaRegFileAlt,
  FaImage,
  FaListAlt,
  FaPlus,
  FaCogs,
} from 'react-icons/fa';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import Register from './Register'; // Ensure the path is correct
import Config from './Config'; // Import the Config component
import PopupAddFeed from '../popups/PopupAddFeed';

const AdminDashboard = ({ token }) => {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [clientStats, setClientStats] = useState([]);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: ''
  });
  const [linkedClients, setLinkedClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isPopupAddFeedOpen, setIsPopupAddFeedOpen] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUsers(response.data);
      } catch (err) {
        console.error(err);
        setMessage('Failed to fetch users');
      }
    };

    const fetchClientStats = async () => {
      try {
        const { startDate, endDate } = filters;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/client-stats`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { start_date: startDate, end_date: endDate }
        });
        setClientStats(response.data);
      } catch (err) {
        console.error(err);
        setMessage('Failed to fetch client stats');
      }
    };

    fetchUsers();
    fetchClientStats();
  }, [token, filters]);

  useEffect(() => {
    const fetchLinkedClients = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/clients`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const clientOptions = response.data.map((client) => ({
          value: client,
          label: client,
        }));
        setLinkedClients(clientOptions);
      } catch (error) {
        console.error('Error fetching linked clients:', error);
      }
    };

    fetchLinkedClients();
  }, [token]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      navigate('/login');
    } catch (err) {
      console.error('Failed to logout', err);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: '#292B3D',
      borderColor: '#E0E1EB',
      boxShadow: 'none',
      fontFamily: 'DM Sans, sans-serif',
      '&:hover': {
        borderColor: '#E0E1EB',
      },
      zIndex: 2,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#292B3D',
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#E0E1EB'
        : '#fff',
      fontFamily: 'DM Sans, sans-serif',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4758EB' : '#E0E1EB',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'DM Sans, sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'DM Sans, sans-serif',
      zIndex: 1000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1000 }),
  };

  // Filter users based on search query
  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handler to open Config modal
  const openConfigModal = (username) => {
    setSelectedUsername(username);
    setIsConfigModalOpen(true);
  };

  return (
    <div className={styles.adminDashboardContainer}>
      <div className={styles.topBar}>
        <button onClick={handleLogout} className={styles.logoutBtn} title="Logout" aria-label="Logout">
          <FaSignOutAlt size={20} />
        </button>

        <div className={styles.headerTextContainer}>
          <span className={styles.feedViewerVersion}>Quantum Feed Engine - Admin Dashboard</span>
          <span className={styles.headerSubtitle}>Manage Users and Clients</span>
        </div>

        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header Image"
          className={styles.headerImage}
        />
      </div>

      {message && <p className={styles.message}>{message}</p>}

      <div className={styles.dashboardContent}>


        {/* Main Content Area */}
        <div className={styles.mainContent}>
          {/* Admin Tools Section */}
          <div className={styles.adminTools}>
            <h2>Quick Links</h2>
            <div className={styles.toolsContainer}>
              <button
                onClick={() => navigate('/qptext')}
                className={`${styles.adminToolsBtn}`}
                title="Text Generation Page"
              >
                <FaRegFileAlt size={20} style={{ marginRight: '8px' }} />
                Text Generation
              </button>
              <button
                onClick={() => navigate('/image-generation')}
                className={`${styles.adminToolsBtn}`}
                title="Image Generation Page"
              >
                <FaImage size={20} style={{ marginRight: '8px' }} />
                Image Generation
              </button>
              <button
                onClick={() => navigate('/custom-attributes')}
                className={`${styles.adminToolsBtn}`}
                title="Custom Attribute Page"
              >
                <FaCogs size={20} style={{ marginRight: '8px' }} />
                Custom Attributes
              </button>
            </div>
          </div>

          {/* User Configuration and Feed Configuration */}
          <div className={styles.leftContainer}>
            <div className={styles.configurationContainer}>
              <div className={styles.tableHeader}>
                <h2>User Configuration</h2>
                <button
                  onClick={() => setIsRegisterModalOpen(true)}
                  className={styles.addUserButton}
                  title="Register New User"
                  aria-label="Register New User"
                >
                  <FaUserPlus size={20} />
                </button>
              </div>
              <div className={styles.searchContainer}>
                <input
                  type="text"
                  placeholder="Search Users"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className={styles.searchInput}
                  aria-label="Search Users"
                />
              </div>
              <div className={styles.userTableContainer}>
                <table className={styles.userTable}>
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Client Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user) => (
                      <tr key={user.username}>
                        <td>{user.username}</td>
                        <td>{user.client_status}</td>
                        <td>
                          <button
                            onClick={() => openConfigModal(user.username)}
                            className={styles.configBtn}
                            title="Configure"
                            aria-label={`Manage Client Feeds for ${user.username}`}
                          >
                            Manage Client Feeds
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Feed Configuration Container */}
            <div className={styles.feedConfigurationContainer}>
                          <div className={styles.tableHeader}>
                            <h2>Feed Configuration</h2>
                            <button
                              onClick={() => setIsPopupAddFeedOpen(true)}
                              className={styles.addFeedButton}
                              title="Add New Feed"
                              aria-label="Add New Feed"
                            >
                              <div className={styles.iconStack}>
                                <FaListAlt size={24} />
                                <FaPlus size={12} className={styles.plusIcon} />
                              </div>
                            </button>
                          </div>
                          {/* Placeholder for future feed configuration features */}
                          {/* You can add a table or list of existing feeds here */}
                        </div>
                      </div>

          {/* Monitoring Section */}
          <div className={styles.monitoringSection}>
            <h2>Monitoring</h2>
            <div className={styles.clientStatsContainer}>
              <div className={styles.filterContainer}>
                <label htmlFor="startDate">
                  Start Date:
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={filters.startDate}
                    onChange={handleFilterChange}
                    className={styles.dateInput}
                    aria-label="Start Date"
                  />
                </label>
                <label htmlFor="endDate">
                  End Date:
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={filters.endDate}
                    onChange={handleFilterChange}
                    className={styles.dateInput}
                    aria-label="End Date"
                  />
                </label>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={clientStats}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="GeneratedItems" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      {/* Register Modal */}
      {isRegisterModalOpen && (
        <div
          className={styles.modalBackdrop}
          onClick={() => setIsRegisterModalOpen(false)}
          role="dialog"
          aria-modal="true"
          aria-labelledby="register-modal-title"
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <Register onClose={() => setIsRegisterModalOpen(false)} />
          </div>
        </div>
      )}

      {/* Config Modal */}
      {isConfigModalOpen && (
        <div
          className={styles.modalBackdrop}
          onClick={() => setIsConfigModalOpen(false)}
          role="dialog"
          aria-modal="true"
          aria-labelledby="config-modal-title"
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <Config
              token={token}
              isAdmin={true} // Assuming the admin dashboard is for admins
              username={selectedUsername}
              onClose={() => setIsConfigModalOpen(false)}
            />
          </div>
        </div>
      )}


      {isPopupAddFeedOpen && (
        <PopupAddFeed
          token={token}
          onClose={() => setIsPopupAddFeedOpen(false)}
        />
      )}


    </div>
  );
};

export default AdminDashboard;
